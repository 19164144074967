import React, {
  Suspense,
  lazy,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import loader from "./assets/img/loader/loading.svg";
import "./assets/css/styles.css";
import AuthContext from "./helpers/AuthContext";
import { authCheck, axiosRemoveToken, axiosSetToken } from "./helpers/api";
import { JWT_TOKEN } from "./helpers/config";
import { SocketContext, socket } from "./helpers/SocketContext";

const HomeScreen = lazy(() => import("./screens/HomeScreen"));
const VipScreen = lazy(() => import("./screens/VipScreen"));
const InviteScreen = lazy(() => import("./screens/InviteScreen"));
const ServerScreen = lazy(() => import("./screens/servers/ServerScreen"));
const RedirectScreen = lazy(() => import("./screens/servers/RedirectScreen"));
const AdminScreen = lazy(() => import("./screens/accounts/AdminScreen"));
const ReviewsScreen = lazy(() => import("./screens/servers/ReviewsScreens"));
const LastMonthScreen = lazy(() => import("./screens/servers/LastMonthScreen"));
const BoostedScreen = lazy(() => import("./screens/BoostedScreen"));
const SearchScreen = lazy(() => import("./screens/SearchScreen"));
const SearchResultScreen = lazy(() => import("./screens/SearchResultScreen"));
const ContactScreen = lazy(() => import("./screens/ContactScreen"));
const BoosterScreen = lazy(() => import("./screens/rankings/BoosterScreen"));
const LifetimeScreen = lazy(() => import("./screens/rankings/LifetimeScreen"));
const WeeklyScreen = lazy(() => import("./screens/rankings/WeeklyScreen"));
const TodayScreen = lazy(() => import("./screens/rankings/TodayScreen"));
const UserSoonScreen = lazy(() => import("./screens/rankings/UserSoonScreen"));
const RequestScreen = lazy(() => import("./screens/programs/RequestScreen"));
const CertifiedScreen = lazy(() =>
  import("./screens/programs/CertifiedScreen")
);
const StreamersScreen = lazy(() =>
  import("./screens/programs/StreamersScreen")
);
const PartnersScreen = lazy(() => import("./screens/programs/PartnersScreen"));
// const ServerAccountScreen = lazy(() =>
//   import("./screens/accounts/ServerScreen")
// );
// const ServerEditAccountScreen = lazy(() =>
//   import("./screens/accounts/ServerEditScreen")
// );
// NEW DASHBOARD
const DashboardChooseScreen = lazy(() =>
  import("./screens/dashboard/ChooseScreen")
);

const DashboardServerHomeScreen = lazy(() =>
  import("./screens/dashboard/servers/HomeScreen")
);

const DashboardServerSettingsScreen = lazy(() =>
import("./screens/dashboard/servers/SettingsScreen")
);

const StatsServerSettingsScreen = lazy(() =>
import("./screens/dashboard/servers/StatsScreen")
);

const HistoryServerSettingsScreen = lazy(() =>
import("./screens/dashboard/servers/HistoryScreen")
);

const SubsServerSettingsScreen = lazy(() =>
import("./screens/dashboard/servers/SubscriptionScreen")
);

const ReviewServerSettingsScreen = lazy(() =>
import("./screens/dashboard/servers/ReviewScreen")
);

// END NEW DASHBOAD

const LegalScreen = lazy(() => import("./screens/legals/LegalScreen"));
const TermScreen = lazy(() => import("./screens/legals/TermScreen"));
const PrivacyScreen = lazy(() => import("./screens/legals/PrivacyScreen"));
const GuidelinesScreen = lazy(() =>
  import("./screens/legals/GuidelinesScreen")
);
const Error404Screen = lazy(() => import("./screens/Error404Screen"));
const LoginScreen = lazy(() => import("./screens/LoginScreen"));
const LogoutScreen = lazy(() => import("./screens/LogoutScreen"));

const LoggedInRoute = ({ ...rest }) => {
  const auth = useContext(AuthContext);
  const referrer = document.location.href.replace(document.location.origin, "");
  return !!auth.authToken ? (
    <Route {...rest} />
  ) : (
    <Redirect to="/auth" from={referrer} push={false} />
  );
};

export default function Routes() {
  const [authState, setAuthState] = useState({
    authToken: null,
    authUser: null,
  });

  const auth = (token, user) => {
    setAuthState({ authToken: token, authUser: user });
  };

  const logout = () => {
    window.localStorage.removeItem(JWT_TOKEN);
    axiosRemoveToken();
    setAuthState({ authToken: null, authUser: null });
  };

  const processToken = useCallback((token) => {
    axiosSetToken(token);
    return authCheck()
      .then((checkRes) => {
        auth(token, checkRes.data.user);
        window.localStorage.setItem(JWT_TOKEN, token);
      })
      .catch((e) => {
        console.error(e);
        window.localStorage.removeItem(JWT_TOKEN);
      });
  }, []);

  useEffect(() => {
    const token = window.localStorage.getItem(JWT_TOKEN);
    if (token) {
      processToken(token);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...authState, auth, logout }}>
      <SocketContext.Provider value={socket}>
        <Router>
          <Suspense
            fallback={
              <div className={"loader"}>
                <img src={loader} alt="Chargement..." />
              </div>
            }
          >
            <Switch>
              <Route exact path="/" component={HomeScreen} />
              <Route exact path="/vip" component={VipScreen} />
              <Route
                path="/invite/:guild_id/:affiliate_id"
                component={InviteScreen}
              />
              <Route path="/server/:id" component={ServerScreen} exact />
              <Route
                path="/redirect/:guild_id"
                component={RedirectScreen}
                exact
              />
              <Route
                path="/server/:id/reviews"
                component={ReviewsScreen}
                exact
              />
              <Route path="/server/:id/last" component={LastMonthScreen} />
              <Route path="/boosted" component={BoostedScreen} />
              <Route path="/search" component={SearchScreen} exact />
              <Route
                path="/search/tags/:tag"
                component={SearchResultScreen}
                exact
              />
              <Route
                path="/search/type/:type"
                component={SearchResultScreen}
                exact
              />
              <Route path="/auth" component={LoginScreen} />
              <Route path="/contact" component={ContactScreen} />
              <Route path="/ranking/guilds/booster" component={BoosterScreen} />
              <Route
                path="/ranking/guilds/lifetime"
                component={LifetimeScreen}
              />
              <Route path="/ranking/guilds/weekly" component={WeeklyScreen} />
              <Route path="/ranking/guilds/today" component={TodayScreen} />
              <Route
                path="/ranking/users/lifetime"
                component={UserSoonScreen}
              />
              <Route path="/ranking/users/monthly" component={UserSoonScreen} />
              <LoggedInRoute
                path="/programs/request"
                component={RequestScreen}
              />
              <Route path="/programs/certified" component={CertifiedScreen} />
              <Route path="/programs/streamers" component={StreamersScreen} />
              <Route path="/programs/partners" component={PartnersScreen} />
              <Route path="/legals/notice" component={LegalScreen} />
              <Route path="/legals/tos" component={TermScreen} />
              <Route path="/legals/privacy" component={PrivacyScreen} />
              <Route path="/legals/guidelines" component={GuidelinesScreen} />
              {/* <LoggedInRoute
                path="/account/servers"
                component={ServerAccountScreen}
              />
              <LoggedInRoute
                path="/account/server/:id/edit"
                component={ServerEditAccountScreen}
              /> */}
              <LoggedInRoute
                path="/account/admin/redirect"
                component={AdminScreen}
              />
              <LoggedInRoute
                path="/dashboard/choose"
                component={DashboardChooseScreen}
              />
              <LoggedInRoute
                path="/dashboard/guild/:id/home"
                component={DashboardServerHomeScreen}
              />
              <LoggedInRoute
                path="/dashboard/guild/:id/settings"
                component={DashboardServerSettingsScreen}
              />
              <LoggedInRoute
                path="/dashboard/guild/:id/stats"
                component={StatsServerSettingsScreen}
              />
              <LoggedInRoute
                path="/dashboard/guild/:id/history"
                component={HistoryServerSettingsScreen}
              />
              <LoggedInRoute
                path="/dashboard/guild/:id/subscription"
                component={SubsServerSettingsScreen}
              />
              <LoggedInRoute
                path="/dashboard/guild/:id/reviews"
                component={ReviewServerSettingsScreen}
              />
              <Route path="/account/logout" component={LogoutScreen} />
              <Route component={Error404Screen} />
            </Switch>
          </Suspense>
        </Router>
      </SocketContext.Provider>
    </AuthContext.Provider>
  );
}
