import axios from 'axios';
import { API_URL } from './config'

let axiosAuthInterceptor = null;

export const axiosRemoveToken = () => {
    if (axiosAuthInterceptor !== null) {
        axios.interceptors.request.eject(axiosAuthInterceptor)
        axiosAuthInterceptor = null
    }
}

export const axiosSetToken = (token) => {
    if (axiosAuthInterceptor === null) {
        axiosAuthInterceptor = axios.interceptors.request.use((config) => {
            config.headers['Authorization'] = `Bearer ${token}`
            return config
        })
    }
}

export const login = async () => {
    return await axios.get(`${API_URL}/discord/redirect`)
}

export const getAccessToken = async (code) => {
    return await axios.get(`${API_URL}/discord/callback?code=${code}`)
}

export const authCheck = async () => {
    return await axios.get(`${API_URL}/auth/user`)
}

export const authAppCheck = async (credientials) => {
    return await axios.get(`${API_URL}/auth/user/${credientials.auth_key}`)
}

export const getUserGuilds = async () => {
    return await axios.get(`${API_URL}/user/dashboard`)
}

export const getUserReviews = async (credientials) => {
    return await axios.get(`${API_URL}/user/${credientials}/reviews`);
}

export const getGuildStatus= async (credientials) => {
    return await axios.get(`${API_URL}/user/@me/guild/${credientials}`)
}

export const getBoosted = async (page) => {
    return await axios.get(`${API_URL}/boosted/${page}`)
}

export const getRanking = async (page) => {
    return await axios.get(`${API_URL}/top/${page}`)
}

export const getGuildDetail = async (id) => {
    return await axios.get(`${API_URL}/guild/${id}`)
}

export const newGuildInvite = async (id) => {
    return await axios.post(`${API_URL}/guild/invite/${id}`)
}

export const getServerCount = async () => {
    return await axios.get(`${API_URL}/servercount`)
}

export const getLifetimeRanking = async () => {
    return await axios.get(`${API_URL}/lifetime`)
}

export const getWeeklyRanking = async () => {
    return await axios.get(`${API_URL}/weekly`)
}

export const getTodayRanking = async () => {
    return await axios.get(`${API_URL}/today`)
}

export const getBoosterRanking = async () => {
    return await axios.get(`${API_URL}/booster`)
}

export const getGuildByName = async (credientials) => {
    return await axios.post(`${API_URL}/search/name`, credientials)
}

export const getGuildByTag = async (credientials) => {
    return await axios.post(`${API_URL}/search/tag`, credientials)
}

export const getGuildByType = async (credientials) => {
    return await axios.post(`${API_URL}/search/type`, credientials)
}

export const newAffiliate = async (credientials) => {
    return await axios.get(`${API_URL}/invite/${credientials.guild}/${credientials.user}`)
}

export const getGuildReviews = async (credientials) => {
    return await axios.get(`${API_URL}/guild/${credientials}/reviews`)
}

export const getGuildUserReview = async (credientials) => {
    return await axios.get(`${API_URL}/guild/${credientials.guild}/user/${credientials.user}/review`)
}

export const newGuildUserReview = async (credientials) => {
    return await axios.post(`${API_URL}/guild/${credientials.ids.guild}/user/${credientials.ids.user}/review`, credientials.review)
}

export const getGuildHistoryVote = async (credientials) => {
    return await axios.get(`${API_URL}/guild/${credientials}/last`)
}

export const newGuildReport = async (credientials) => {
    return await axios.post(`${API_URL}/guild/${credientials.guildId}/report`, credientials)
}

export const editGuild = async (id, credientials) => {
    return await axios.put(`${API_URL}/guild/${id}`, credientials);
}

export const getStatusVoteBooster = async () => {
    return await axios.get(`${API_URL}/statusBooster`);
}

export const newVoteBooster = async (credientials) => {
    return await axios.post(`${API_URL}/guild/${credientials}/buy/voteBooster`);
}

export const newGuild = async (credientials) => {
    return await axios.post(`${API_URL}/guild/${credientials}`);
}

export const checkCertified = async (credientials) => {
    return await axios.get(`${API_URL}/guild/${credientials}/certified`);
}

export const setCertified = async (credientials) => {
    return await axios.post(`${API_URL}/guild/${credientials.guild_id}/certified`, credientials);
}

export const newContact = async (credientials) => {
    return await axios.post(`${API_URL}/contact`, credientials);
}

export const checkCodePromo = async (code) => {
    return await axios.get(`${API_URL}/checkCode/${code}`)
}

export const newGuildAd = async (credientials) => {
    return await axios.post(`${API_URL}/guild/${credientials.guildId}/ad`, credientials)
}

export const getGuildAdWaiting = async () => {
    return await axios.get(`${API_URL}/adWaiting`)
}

export const getGuildAd = async () => {
    return await axios.get(`${API_URL}/ad`)
}

export const getGuildAdLimit = async (credentials) => {
    return await axios.get(`${API_URL}/server/${credentials}/ad/limit`)
}

export const getDashboardGuildInfo = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/guild/${credentials}/show`)
}

export const getDashboardGuildHeadStats = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/guild/${credentials}/showHeadStats`)
}

export const getDashboardGuildActivity = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/guild/${credentials}/showActivity`)
}

export const getDashboardGuildStats = async (credentials) => {
    return await axios.post(`${API_URL}/dashboard/guild/${credentials.id}/stats`, { selectedMonth: credentials.selectedMonth })
}

export const getDashboardGuildHistoryRanking = async (credientials) => {
    return await axios.post(`${API_URL}/dashboard/guild/${credientials.id}/history`, { selectedMonth: credientials.selectedMonth })
}

export const getDashboardGuildReview = async (credientials) => {
    return await axios.get(`${API_URL}/dashboard/guild/${credientials}/review`)
}

export const putDashboardGuildReview = async (review_id, admin_id) => {
    return await axios.put(`${API_URL}/dashboard/${admin_id}/review/${review_id}/publish`)
}

export const deleteDashboardGuildReview = async (review_id, admin_id) => {
    return await axios.delete(`${API_URL}/dashboard/${admin_id}/review/${review_id}/publish`)
}